export const blobUrl = 'https://ihadev.blob.core.windows.net';
//export const API = "https://talentou-api.ignithocloud.com/api/v3"
function apiGenerator() {
    // get host_name
    const hostName = window.location.hostname;
    if (hostName === "localhost") {
        // return "https://talentou-api.ignithocloud.com/api/v3"
        return "https://ignitho-api.talentou.com/api/v3"
          // return "http://localhost:5555/api/v3"
    } else {
        // Split the string into an array of words
        const domainKeys = hostName.split('.');
        // Ensure there are at least three words
        if (domainKeys.length >= 3) {
            // Get the first word and append "-api"
            const tenantKey = domainKeys[0] + "-api";
            console.log(tenantKey);
            return "https://" + tenantKey + '.' + domainKeys.slice(1).join('.') + "/api/v3";

        } else {
            console.log("Sorry, invalid tenant, please contact Talentou Support");
            return;
        }
    }
}

export const API = apiGenerator();